.faq-main {
    margin-top: 130px;
    padding-right: 86px;
    padding-left: 86px;
}
.faq-q {
    display: list-item;
    list-style-type: disc; 
    list-style-position: inside;
    color:rgba(39, 56, 71, 1);
    font-size:30px;
    font-family: Releway-medium;
    font-weight: bold;
} 
.faq-a {
    padding-left: 42px;
    font-size:14px;
    font-family: Releway-medium;
} 
.faq-item {
    margin-bottom: 78px;
}
.faq-item:last-child {
    margin-bottom: 228px;
}
@media (max-width:1100px) {
    .faq-main {
        margin-top: 130px;
        padding-right: 86px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .faq-a {
        padding-left: 0px;
        font-size:14px;
        font-family: Releway-medium;
    } 
}