.lk-personalData
    width: 486px
    font-family: Releway-regular
    @media ( max-width: 1000px )
        width: 100%
        
    .lk-wallet
        font-size: 20px
        color: #273847
        margin: 20px 0
        @media screen and ( max-width: 1000px )
            font-size: 14px
            text-overflow: ellipsis
    .lk-title2
        font-size: 14px
        color: #273847
    .lk-title3
        font-size: 14px
        color: #273847
        margin: 20px 0px 10px 0px

    .lk-amountFlex
        display: flex
        justify-content: space-between
        margin-top: 15px
        margin-bottom: 35px
        .lk-amount
            font-size: 24px
            color: #273847
            
            span
                font-size: 16px
                color: #959EA7
            button
                font-size: 16px
                margin-left: 30px
                font-family: Releway-regular
        .lk-amountButton
            background: none
            border: none
            color: #894ADA
            font-family: Releway-semiBold

.lk-personalData
    input
        width: 100%
        height: 35px
        border: 1px solid #F2E9EA
        box-sizing: border-box
        border-radius: 8px
        margin-bottom: 16px

.lk-darkButton
    height: 50px
    width: 174px
    background-color: #8A4ADA
    color: white
    border: none
    border-radius: 8px
    margin-top: 24px
    margin-right: 12px
.lk-lightButton
    height: 50px
    width: 174px
    background-color: rgba(138, 74, 218, 0.25)
    color: #894ADA
    border: none
    border-radius: 8px
    margin-top: 24px
    margin-right: 12px
.lk-input
    height:30px 
    width:100%
    outline: none
    border: none
    border-radius: 4px
    font-family: Montserrat-regular
    padding: 6px
    margin-bottom: 10px
.lk-analyticTitle
    font-family: Releway-semiBold
    font-size: 18px
    display: flex
    align-items: center
    margin: 20px 0 10px 0
    img
        margin-right: 8px
.lk-blog
    height:70px
    width:100% 

.lk-textarea
    width:100%
    height:100px
    border: none
    border-radius: 4px
    font-family: Montserrat-regular
    padding: 6px
    resize: none
    outline: none

.lk-myNft
    width: 100%
.lk-rooms 
    width:100%
    height:113px
    object-fit: cover
.lk-gap
    margin-right: 14px
.lk-titles
    text-align: center
    
.lk-bigTitle
    margin-top: 20px 
    margin-bottom: 20px 
.lk-message
    width: 100%
    border-radius: 16px
    overflow: hidden
    min-height: 252px
    box-shadow: 0px 2px 15px rgba(39, 56, 71, 0.15)
    margin-bottom: 40px
.lk-title
    font-family: Releway-Bold
.lk-blog-preview-modal
    height:50px
    display: flex
    align-items: center
    width:100%
    background-size: cover
    font-family: Releway-Bold
    padding: 20px
    border-top: 1px solid #F1EBE9
    cursor: pointer
    &:hover
        background-color: #F1EBE9
    &:first-child
        border-radius: 7px
.lk-blog-part
    font-family: Releway-medium
    background-color: #F1EBE9
    border: 2px solid #F1EBE9
    width: fit-content
    border-radius: 8px
    align-items: center
    backdrop-filter: blur(2px)
    padding-left: 5px
    padding-right: 5px
    img
        width:9px 
        height:9px
        margin-left:10px
.lk-blog-preview-modal-text
    color: black
    margin: 0
.lk-blog-icons
    display: flex
    justify-content: space-between
    align-items: center
    height: 40px
.lk-text
    font-family: 'Segoe UI'
.lk-blog-file
    position: absolute
    width:17px
    height:17px
    opacity:0 
        
.lk-nft
    font-family: 'Segoe UI'
    color: #894ADA
    cursor: pointer
    &:hover
        text-decoration: underline
.mynft-container
    display: grid
    grid-template-columns: repeat(4,1fr)
    column-gap: 15px
    row-gap: 15px
    margin: 0 0 40px 0
    @media screen and ( max-width: 1200px )
        grid-template-columns: repeat(3,1fr)
    @media screen and ( max-width: 1000px )
        grid-template-columns: 1fr
        