.fade {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.533);
  display: flex;
  align-items: center;
  justify-content: center;
}
.fade .modal {
  width: 300px;
  background: #444;
  padding: 10px;
}
