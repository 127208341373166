.ct-container
    padding: 64px
    @media ( max-width: 600px )
        padding: 15px
        padding-top: 60px
        
.ct-filters
    display: flex
    justify-content: space-between
    margin-bottom: 24px
    
    button
        height: 49px
        width: 112px
        background: #F1EBE9
        backdrop-filter: blur(2px)
        border-radius: 99px
        border: none
        margin-right: 8px
    div
        display: flex
        flex-wrap: wrap
        margin-right: 20px
        margin-bottom: 10px

        button
            border-radius: 8px

.ct-contentGrid
    display: grid
    grid-template-columns: repeat(5, 1fr)
    column-gap: 12px
    row-gap: 12px
    @media ( max-width: 1500px )
        grid-template-columns: repeat(3, 1fr)
    @media ( max-width: 1000px )
        grid-template-columns: repeat(2, 1fr)
    @media ( max-width: 800px )
        grid-template-columns: 1fr 
.ct-background 
    z-index: 9999
    background-color: white
    padding:12px
    display: grid
    grid-template-rows: max-content max-content 1fr
.lazy-load-image-background
    width: 100%
.noImage
    height: 252px
    width:100%
    object-fit: cover
    overflow: hidden
    transition: 700ms ease all
    opacity: 0 
.changedImage
    height: 252px
    width:100%
    object-fit: cover
    overflow: hidden
    transition: 700ms ease all
    opacity: 1
.noVideo
    transition: 700ms ease all
    opacity: 0
.changedVideo
    transition: 700ms ease all
    opacity: 1
    cursor: pointer    
.ct-contentItem
    border-radius: 16px
    overflow: hidden
    box-shadow: 0px 2px 15px rgba(39, 56, 71, 0.15)
    display: grid
    grid-template-rows: 252px 1fr 
    video
        height: 252px
        width: 100%
        object-fit: cover
        overflow: hidden
    img
        height: 252px
        width: 100%
        object-fit: cover
        overflow: hidden
   
    .ct-contentData
        background: white
        display: flex
        justify-content: space-between
        align-items: center
        font-family: Releway-regular
        font-size: 14px
        img
            width: 24px !important
            height: 24px !important
            margin-right: 5px
            border-radius: 100%
            overflow: hidden
        p
            margin: 0
            margin-left: auto
            color: #959EA7
            span
                font-family: Releway-bold
                color: #273847
                font-size: 16px


    .ct-contentAucTime
        display: flex
        align-items: center
        color: #959EA7
        justify-content: space-between
        align-items: flex-end
        p
            width: max-content !important
    .ct-contentAucTimeIco 
        width: 16px
        height: 16px
        margin-right: 7px
    .ct-contentTitle
        font-family: Releway-bold
        font-size: 20px
        color: #273847
        margin: 10px 0px 6px 0px
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden
        
    .ct-contentTags
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        font-size: 14px
        color: #894ADA
        align-items: flex-end
        p
            margin: 0
            margin-right: 10px
            white-space: normal
            width: 40%
            