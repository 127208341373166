.news-title
    margin-top: 64px
.news-container
    overflow-x: hidden !important
    overflow-y: hidden !important
    min-height: calc( 100vh - 130px - 65px )
    padding-left: 95px
    padding-right: 95px
    @media (max-width:700px)
        padding-left: 25px
        padding-right: 25px
.news-banner 
    display: flex
    justify-content: space-between
    margin-bottom: 153px
    @media (max-width:1255px)
        flex-direction: column
        align-items: flex-start
        
.news-banner-nft
    margin-top:58px
    width: 37vw 
    margin-bottom: 153px
    height: 400px
    @media (max-width: 1255px)
        margin-bottom: 40px
        max-width: auto
        min-width: 100%
        height: 430px
         
.news-filterList 
    width:80vw
    margin-left: 10vw
    flex-wrap: wrap
.news-gallery 
    width: 94vw
    overflow-y: scroll
    margin-top:122px
    margin-bottom: 230px 
.news-galleryItem 
    width: 300px!important
    margin-right:10px
    height:200px
    border-radius:8px
    display: flex
    flex-direction: column
    alighn-items: center
    justify-content: center
.news-galleryItemText 
    width: 300px
    height:200px!important
    display: flex
    border-radius:8px
    align-items: center
    justify-content: center    
    height: fit-content
    font-family: Releway-medium
    color: white
    background: rgba(0,0,0,0.5)
    padding: 10px

    font-size: 24px
.news-text
    font-size: 64px
    font-weight: bold 
    width: 40vw 
    margin-top: 65px
    @media (max-width:1255px)
        width: 80vw 
    @media (max-width:558px)
        font-size: 34px
.news-subtext
    font-size: 24px
    margin-top: 13px
    margin-bottom: 45px
    @media (max-width:1255px)
        width: 90vw 
    @media (max-width:558px)
        font-size:18px
.news-subtitle 
    text-align: center   
    font-family: Releway-medium
    font-weight: 600
    font-size: 24px