
.bg-black {
  background-color: #000 !important;
}

.bg-gradient-primary-to-secondary {
  background: linear-gradient(45deg, #e5cee2, #d5ddf5) !important;
}

.font-alt {
  font-family: "Kanit", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.text-gradient {
  background: -webkit-linear-gradient(#e5cee2, #d5ddf5);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

aside,
section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.app-badge {
  height: 3rem;
}

#mainNav {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #fff;
}
#mainNav .navbar-brand {
  font-family: "Kanit", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
#mainNav .navbar-brand:hover, #mainNav .navbar-brand:active {
  color: #e5cee2;
}
#mainNav .navbar-toggler {
  font-size: 0.875rem;
  padding: 0.75rem 1rem;
  color: #212529;
}
#mainNav .navbar-nav .nav-item .nav-link {
  color: #212529;
}
#mainNav .navbar-nav .nav-item .nav-link:hover {
  color: #6c757d;
}
#mainNav .navbar-nav .nav-item .nav-link.active {
  color: #e5cee2;
}

/*!
 * html5-device-mockups (https://github.com/pixelsign/html5-device-mockups)
 * Copyright 2013 - 2017 pixelsign
 * Licensed under MIT (https://github.com/pixelsign/html5-device-mockups/blob/master/LICENSE.txt)
 * Last Build: Tue Nov 14 2017 12:40:57
 */
.device-wrapper {
  max-width: 300px;
  width: 100%;
}

.device {
  position: relative;
  background-size: cover;
}

.device::after {
  position: absolute;
  background-size: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.device .screen {
  position: absolute;
  background-size: cover;
  pointer-events: auto;
}

.device .button {
  position: absolute;
  cursor: pointer;
}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black] {
  padding-bottom: 198.898071625%;
}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black] .screen {
  top: 3.1855955679%;
  left: 6.8870523416%;
  width: 86.2258953168%;
  height: 93.6288088643%;
}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black]::after {
  content: "";
  background-image: url("img/portrait_black.png");
}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black] .button {
  display: none;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

section.cta {
  position: relative;
  padding: 15rem 0;
  background-image: url("https://source.unsplash.com/5g41N3uRPf8/1500x800");
  background-position: center;
  background-size: cover;
}
section.cta .cta-content {
  position: relative;
  z-index: 1;
}
section.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.icon-feature {
  font-size: 4rem;
}

.features-device-mockup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.features-device-mockup .circle {
  position: absolute;
  left: 0;
  fill: url(#circleGradient);
  width: 70%;
  display: none;
}
.features-device-mockup .circle .gradient-start-color {
  stop-color: #e5cee2;
}
.features-device-mockup .circle .gradient-end-color {
  stop-color: #d5ddf5;
}
.features-device-mockup .shape-1 {
  position: absolute;
  height: 1rem;
  width: 1rem;
  transform: rotate(10deg);
  fill: #e5cee2;
  top: 15%;
  right: 10%;
}
.features-device-mockup .shape-2 {
  position: absolute;
  height: 0.75rem;
  width: 0.75rem;
  fill: #e5cee2;
  bottom: 15%;
  left: 10%;
}
.features-device-mockup .device-wrapper {
  max-width: 10rem;
}

@media (min-width: 576px) {
  .features-device-mockup .circle {
    width: 70%;
    display: block;
    left: auto;
  }
  .features-device-mockup .device-wrapper {
    max-width: 12.5rem;
  }
}
@media (min-width: 768px) {
  .features-device-mockup .circle {
    width: 60%;
    left: auto;
  }
  .features-device-mockup .device-wrapper {
    max-width: 15rem;
  }
}
@media (min-width: 992px) {
  .features-device-mockup .circle {
    width: 90%;
    left: -25%;
  }
  .features-device-mockup .shape-1 {
    top: 5%;
    right: -15%;
  }
  .features-device-mockup .shape-2 {
    bottom: 10%;
    left: -10%;
  }
  .features-device-mockup .device-wrapper {
    max-width: 15rem;
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  .features-device-mockup .circle {
    width: 75%;
    left: 0;
  }
  .features-device-mockup .shape-2 {
    bottom: 15%;
    left: 10%;
  }
  .features-device-mockup .device-wrapper {
    max-width: 15rem;
  }
}
@media (min-width: 1400px) {
  .features-device-mockup .circle {
    width: 80%;
    left: 0;
  }
  .features-device-mockup .device-wrapper {
    max-width: 17rem;
  }
}
footer a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
footer a:hover, footer a:active, footer a:focus {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: underline;
}

.masthead {
  background-color: #f8f9fa;
  padding-top: 9.5rem;
  padding-bottom: 5rem;
}
.masthead .masthead-device-mockup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.masthead .masthead-device-mockup .circle {
  position: absolute;
  fill: url(#circleGradient);
  width: 70%;
  display: none;
}
.masthead .masthead-device-mockup .circle .gradient-start-color {
  stop-color: #e5cee2;
}
.masthead .masthead-device-mockup .circle .gradient-end-color {
  stop-color: #d5ddf5;
}
.masthead .masthead-device-mockup .shape-1 {
  position: absolute;
  height: 1rem;
  width: 1rem;
  transform: rotate(-10deg);
  fill: #e5cee2;
  bottom: 15%;
  left: 10%;
}
.masthead .masthead-device-mockup .shape-2 {
  position: absolute;
  height: 0.75rem;
  width: 0.75rem;
  fill: #e5cee2;
  top: 15%;
  right: 10%;
}
.masthead .masthead-device-mockup .device-wrapper {
  max-width: 10rem;
}
@media (min-width: 576px) {
  .masthead .masthead-device-mockup .circle {
    width: 70%;
    display: block;
  }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 12.5rem;
  }
}
@media (min-width: 768px) {
  .masthead .masthead-device-mockup .circle {
    width: 60%;
  }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 15rem;
  }
}
@media (min-width: 992px) {
  .masthead .masthead-device-mockup .circle {
    width: 90%;
  }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 15rem;
  }
}
@media (min-width: 1200px) {
  .masthead .masthead-device-mockup .circle {
    width: 75%;
  }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 15rem;
  }
}
@media (min-width: 1400px) {
  .masthead .masthead-device-mockup .circle {
    width: 70%;
  }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 17rem;
  }
}